import React, { useState } from 'react';
import styled from "styled-components";
import Modal from "react-modal";
import PText from "../components/PText";
import RecomKba from '../assets/images/RecomKba.png';

const SSNotesPagesStyles = styled.div`
  padding: 10rem 0;
  .top-section {
  margin:4rem 2rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color: rgba(0,255,0,0.1);
    padding: 1rem;
  }
  .left {
    flex: 4;
  }
  .right {
    flex: 2;
  }
  .ssnotes__heading {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
  .my_notes-section {
    width: 100%;
    margin-top: 2rem;
  }
   .kailaba__info {
    margin-bottom: 1rem;
    padding:1rem;
    .link_button{
      margin-top:1em;
      padding:.5em 2em;
      outline:none;
      border-radius:5;
    }
    .para {
      max-width: 100%;
    }
  }
  .my_note-info {
    font-size: 2rem;
  }
  .note_info{
  font-size: 1.6rem;
   line-height: 1.6em;
  font-family: poppins, sans-serif;
  color: white;
  margin-bottom:1rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
  }

  .all-subjects {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    .each-subjects {
      flex: 1 1 calc(50% - 1rem);
      padding: 1rem;
      background-color: var(--deep-dark);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      margin-top:1rem;
      margin: auto;
      button {
        font-size: 1em;
        cursor: pointer;
        background-color: cyan;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 5px;
      }
    }
  }
`;

const ModalStyles = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  .modal-content {
    width: 95%;
    height: 80%;
    background: white;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 16px;
    font-width:bold;
    background: black;
    color: white;
    border: none;
    padding: 3px 8px;
    border-radius: 50%;
    cursor: pointer;
  }
  iframe {
    flex: 1;
    width: 100%;
    height: 100%;
  }
`;

const subject_lists = [
  {
    id: 1,
    name: "Discrete Structure",
    link: "https://docs.google.com/presentation/d/1S1gzmiReKHYK4vWYTo8x8ry-BXH9pdXmItot8JUIPjU/edit?usp=sharing"
  },
  {
    id: 2,
    name: "Computer Architecture",
    link: "https://docs.google.com/presentation/d/1bXYlKGzxBg00o2FEs174WDzYBvjn9w0pxHr2r2_3_tM/edit?usp=sharing"
  },
  {
    id: 3,
    name: "Artificial Intelligence",
    link: "https://www.canva.com/design/DAGI6YA0DJ4/ZlA-8hI45PVvLUP58FdS_A/view?utm_content=DAGI6YA0DJ4&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h9361483cbf"
  },
  {
    id: 4,
    name: "Computer Graphics",
    link: "https://www.canva.com/design/DAFveAWFUd0/bbsZRIIAKGs9Q2b-s_5HqQ/view?utm_content=DAFveAWFUd0&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=heddfe14ed5"
  },
  {
    id: 5,
    name: "Introduction to IT & Application",
    link: "https://docs.google.com/presentation/d/13_daovPkn1MNIjQS-CF-90RTSDU-UZ5nOTuzDqUa0-M/edit?usp=sharing"
  },
  {
    id: 6,
    name: "Image Processing",
    link: "https://docs.google.com/presentation/d/1uKPe9m81N1PvntbZMqD7AOHMArfUQLBXXF93633onrQ/edit?usp=sharing"
  }
];

export default function SSNotes() {
  const [selectedSlide, setSelectedSlide] = useState(null);
  
  return (
    <>
      <SSNotesPagesStyles>
        <div className="container">
          <h2 className="ssnotes__heading">Sujan Sir Ko Notes:</h2>
   
          <div className='my_notes-section'>
            <h3 className='my_note-info'>
              I am currently working as a Part Time <span style={{ color: "cyan" }}>"Visiting Faculty"</span> Lecturer at Vedas College (TU) and also <span style={{ color: "cyan" }}>Part Time Faculty</span> Lecturer at St. Xavier's College (TU). 
            </h3>
            <br></br>
            <br></br>
            <PText>
              <p className="sh-info">Here are the subjects I teach.</p>
            </PText>
            <div className='all-subjects'>
              {subject_lists.map((item) => (
                <div className='each-subjects' key={item.id}>
                  <PText >{item.name} </PText>
                  <button style={{color:"black"}} onClick={() => setSelectedSlide(item.link)}>View</button>
                </div>
              ))}
            </div>
          </div>
          <div className="top-section">
            <div className="left">
              <div className="kailaba__info">
                <p className='note_info'>
                  For All Engineering Notes and Resources, I prefer to visit Kailaba.com
                  <br />
                </p>
                <button className="link_button">
                  <a
                    href="https://kailaba.com/engineering-notes-kailaba/"
                    style={{ color: "black", fontSize: "1rem" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Kailaba
                  </a>
                </button>
              </div>
            </div>
            <div className="right">
              <img src={RecomKba} alt="Recommended Notes" />
            </div>
          </div>

        </div>
      </SSNotesPagesStyles>

      {/* Modal */}
      <ModalStyles
        isOpen={!!selectedSlide}
        onRequestClose={() => setSelectedSlide(null)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <div className="modal-content">
          <button className="close-btn" onClick={() => setSelectedSlide(null)}>X</button>
          {selectedSlide && (
            <iframe
              src={selectedSlide}
              frameBorder="0"
              allowFullScreen
              title="Google Slides"
            ></iframe>
          )}
        </div>
      </ModalStyles>
    </>
  );
}
