import React from "react";
import styled from "styled-components";
import PText from "../components/PText";
import AboutImg from "../assets/images/AbtP.jpg";
import AboutInfoItem from "../components/AboutInfoItem";
import AbtSi from '../assets/images/abtpg.png'
import ContactBanner from "../components/ContactBanner";
import { Link } from 'react-router-dom';

const AboutPageStyles = styled.div`
  padding: 10rem 0 10rem 0;

  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.3rem;
  }
  .left {
    flex: 4;
  }
  .right {
    flex: 2;
  }
  .about__subheading {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    span {
      background-color: var(--deep-dark);
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  .about__heading {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
  .about__info {
    margin-bottom: 4rem;
    .para {
      max-width: 100%;
    }
  }
  .right {
    img {
      border: 2px solid var(--gray-1);
    }
  }
  .about__info__items {
    margin-top: 15rem;
  }
  .about__info__item {
    margin-bottom: 10rem;
  }
  .about__info__heading {
    font-size: 3.6rem;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 768px) {
    .container {
      padding: 2rem;
    }
    padding: 10rem 0;
    .top-section {
      flex-direction: column;
      gap: 5rem;
    }
    .about__subheading {
      font-size: 1.8rem;
    }
    .about__heading {
      font-size: 2.8rem;
    }
    .about__info__heading {
      font-size: 3rem;
    }
  }
`;

export default function About() {
  return (
    <>
      <AboutPageStyles>
        <div className="container">
          <div className="top-section">
            <div className="left">
              <p className="about__subheading">
                Hi, I am <span>Sujan Sharma</span>
              </p>
              <h2 className="about__heading">Computer Engineer</h2>
              <div className="about__info">
                <PText>
                  I am from Bharatpur-11, Chitwan. Since my childhood, i love
                  art and design and thinking innovtive. I always try to design
                  stuff with my unique point of view & I also love to create the
                  things that can be usefull to others in their daily life.
                  <br /> <br />
                  I started coding lately, since I was in second year. 
                  After that, There is NO going back, Coding is an
                  art for me. I love it and now I have the opportunity to
                  design, analyze the things along with the coding.
                  <br />
                  
                </PText>
              </div>
              <button btnText="View CV" btnLink="#" style={{padding:"1em 4em",borderRadius:8}}>
                <a
                  href="https://rxresu.me/invalidsb/sujanoriginal"
                  // to="/sujans's-resume" target="_blank"
                  // download="Sujan'sResume.pdf"
                  style={{color:"black",fontSize:"2rem"}}
                >
                  View Old CV
                </a>

              </button>
            </div>
            <div className="right">
              <img src={AbtSi} alt="me" />
            </div>
          </div>
          {/* <Link
to={"/myresume"}
style={{backgroundColor:"red"}}
>
.
</Link> */}

          <div className="about__info__items">
            <div className="about__info__item">
              <h1 className="about__info__heading">Education</h1>

              <AboutInfoItem
                title="School"
                items={["Shree Naya kiran Ma.vi, Bhojard"]}
              />
              <AboutInfoItem
                title="College"
                items={["Prerana Higher Secondary School"]}
              />
              <AboutInfoItem title="Uni" items={["Pokhara University"]} />
            </div>
            <div className="about__info__item">
              <h1 className="about__info__heading">My Skills</h1>

              <AboutInfoItem
                title="FrontEnd"
                items={["HTML", "CSS", "JS", "REACT"]}
              />
              <AboutInfoItem
                title="BackEnd"
                items={["Node", "Express", "Django"]}
              />
              <AboutInfoItem
                title="AI/ML"
                items={["NLP","CV","......"]}
              />
            </div>
          </div>

          {/* <Link to="/my-certificates">
          <PText >
Achievements
</PText>
          </Link> */}
        </div>
        <ContactBanner />
      </AboutPageStyles>
    </>
  );
}
